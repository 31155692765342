import React, { useEffect, useRef } from 'react'
import Layout from '../components/layout/layout'
import { Box, Container, Text, Heading, Link } from '@chakra-ui/react'
import { gtmPush } from '../utils/analytics'
import Bugsnag from '@bugsnag/js'
import useQueryParams from '../hooks/useQueryParams'
import ShareButtons from '../components/functionality/shareButtons'
import MotionBox from '../components/elements/motionBox'
import { eventData } from '../api/eventData'
import { useContactInformation } from '../api/queries'

function reportProductConversion(data) {
  const { currency, amount, product, id } = data
  gtmPush({
    event: 'ee.transaction',

    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id,
          affiliation: '1',
          revenue: amount,
          tax: '0',
          shipping: '0',
        },

        products: [
          {
            name: product,
            price: amount,
            quantity: 1,
          },
        ],
      },
    },
  })
}

function useConversionTracking(queryParams) {
  // Example URL with parameters
  // https://isha.sadhguru.org/karma/thank-you/?product=patron-package&currency=USD&amount={amount}&id={id}
  const reportedConversion = useRef(false)
  useEffect(() => {
    if (reportedConversion.current) return

    if (queryParams.product) {
      reportProductConversion(queryParams)
      reportedConversion.current = true
    } else {
      Bugsnag.notify(
        new Error('Missing product query parameter on thank you page'),
        (event) => {
          event.addMetadata('query-params', queryParams)
        }
      )
    }
  }, [queryParams])
}

const getEventData = (product) =>
  product
    ? Object.values(eventData).find(
        (datum) => datum.customStripe && product.startsWith(datum.customStripe)
      )
    : null

const isPackage = (product) =>
  product === 'patron-package' || product === 'sponsor-package'

function getShareContent(product) {
  const productIsPackage = isPackage(product)

  const baseShareText = `Are you ready to #craftyourdestiny? Join me for the #Karma Virtual Book Tour with @Sadhguru`
  const baseWhatsappText = `Are you ready to craft your destiny? Join me for the Karma Virtual Book Tour with Sadhguru`

  const data = getEventData(product)

  if (!data && !productIsPackage) return null

  return {
    shareText: {
      twitter: baseShareText + (productIsPackage ? '' : ' & ' + data.twitter),
      whatsapp: baseWhatsappText + (productIsPackage ? '' : ' & ' + data.name),
    },
    url: productIsPackage
      ? 'https://isha.sadhguru.org/karma/package/'
      : 'https://isha.sadhguru.org/karma/events/',
  }
}

function ThankYouShareButtons({ product }) {
  const shareContent = getShareContent(product)

  if (!shareContent) return null

  return (
    <MotionBox
      initial={'initial'}
      animate={'show'}
      variants={{
        initial: { opacity: 0 },
        show: {
          opacity: 1,

          transition: {
            staggerChildren: 0.2,
            delayChildren: 0.5,
            duration: 0.8,
            delay: 0.3,
          },
        },
      }}
    >
      <Text align={'center'} fontSize={'2xl'} mt={8}>
        Share with others!
      </Text>
      <ShareButtons {...shareContent} />
    </MotionBox>
  )
}

export function ThankYouPageContent({ product }) {
  const productIsPackage = isPackage(product)

  const { email, phone } = useContactInformation()

  return (
    <Box bgColor={'tan.50'} py={16} minHeight={'100vh'}>
      <Container textAlign={'center'} maxW={'container.md'}>
        <Heading fontSize={'64px'} color={'blue'} mb={6}>
          Thank You
        </Heading>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Text fontSize={'2xl'} mb={'1em'}>
            {productIsPackage
              ? 'You are registered for the Karma virtual tour!'
              : 'You are registered for the event!'}
          </Text>
          <Text fontSize={'md'} textAlign={'left'}>
            You will receive a confirmation email and a separate email with the
            access code, along with other details needed to log in to the event
            platform, within 30 minutes. If you don’t receive the email within
            30 minutes, please reach out to us at{' '}
            <Link href={`mailto:${email}`} variant={'text'}>
              {email}
            </Link>{' '}
            or{' '}
            <Link href={`tel:${phone}`} variant={'text'} whiteSpace={'nowrap'}>
              {phone}
            </Link>
            .
          </Text>
        </MotionBox>
      </Container>

      {product && <ThankYouShareButtons product={product} />}
    </Box>
  )
}

export default function ThankYouPage() {
  const queryParams = useQueryParams()
  useConversionTracking(queryParams)

  return (
    <Layout>
      <ThankYouPageContent product={queryParams.product} />
    </Layout>
  )
}
